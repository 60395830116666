body {
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
}

#gradient-canvas {
    /* css for animation */
    width: 100%;
    height: 100%;
    --gradient-color-1: #449ce4;
    --gradient-color-2: #2f8bc1;
    --gradient-color-3: #ccbeee;
    --gradient-color-4: #4c57f6;

    /* css for diagonal line 
    overflow: hidden;
    transform: skewY(-12deg);
    transform-origin: 0;*/

    /*css for overlaying */
    z-index: 1;

    clip-path: polygon(100% 0%, 0% 0%, 0.00% 93.85%, 2.00% 94.60%, 4.00% 95.23%, 6.00% 95.75%, 8.00% 96.16%, 10.00% 96.44%, 12.00% 96.61%, 14.00% 96.67%, 16.00% 96.60%, 18.00% 96.42%, 20.00% 96.12%, 22.00% 95.70%, 24.00% 95.17%, 26.00% 94.52%, 28.00% 93.76%, 30.00% 92.89%, 32.00% 91.92%, 34.00% 90.83%, 36.00% 89.64%, 38.00% 88.36%, 40.00% 86.97%, 42.00% 85.50%, 44.00% 83.93%, 46.00% 82.28%, 48.00% 80.54%, 50.00% 78.73%, 52.00% 76.85%, 54.00% 74.90%, 56.00% 72.88%, 58.00% 70.81%, 60.00% 68.68%, 62.00% 66.51%, 64.00% 64.30%, 66.00% 62.05%, 68.00% 59.77%, 70.00% 57.46%, 72.00% 55.14%, 74.00% 52.80%, 76.00% 50.46%, 78.00% 48.11%, 80.00% 45.77%, 82.00% 43.44%, 84.00% 41.13%, 86.00% 38.84%, 88.00% 36.57%, 90.00% 34.35%, 92.00% 32.16%, 94.00% 30.01%, 96.00% 27.92%, 98.00% 25.88%, 100.00% 23.90%);
    /*clip-path: polygon(100% 0%, 0% 0%, 0.00% 95.96%, 2.00% 96.23%, 4.00% 96.44%, 6.00% 96.58%, 8.00% 96.65%, 10.00% 96.66%, 12.00% 96.60%, 14.00% 96.48%, 16.00% 96.29%, 18.00% 96.03%, 20.00% 95.71%, 22.00% 95.33%, 24.00% 94.88%, 26.00% 94.36%, 28.00% 93.79%, 30.00% 93.15%, 32.00% 92.44%, 34.00% 91.68%, 36.00% 90.86%, 38.00% 89.98%, 40.00% 89.05%, 42.00% 88.06%, 44.00% 87.01%, 46.00% 85.92%, 48.00% 84.77%, 50.00% 83.57%, 52.00% 82.32%, 54.00% 81.03%, 56.00% 79.70%, 58.00% 78.32%, 60.00% 76.90%, 62.00% 75.44%, 64.00% 73.95%, 66.00% 72.42%, 68.00% 70.87%, 70.00% 69.28%, 72.00% 67.66%, 74.00% 66.02%, 76.00% 64.36%, 78.00% 62.68%, 80.00% 60.97%, 82.00% 59.26%, 84.00% 57.53%, 86.00% 55.78%, 88.00% 54.03%, 90.00% 52.28%, 92.00% 50.52%, 94.00% 48.76%, 96.00% 47.00%, 98.00% 45.25%, 100.00% 43.51%);*/
}


.header-container {
    position: absolute;
    width: 30%;
    z-index: 2;
    top: 15%;
    left: 10%;
}

.header-container h1 {
    font-size: 55px;
    /*color: rgb(4, 11, 81);*/
    /*color: rgb(18, 20, 105);*/
    color: white;
    text-align: left;
    font-weight: 400;
    width: 100%;
}

.header-container p {
    color: white;
    font-size: 18px;
    font-weight: 400;
}
/*
.svg-header-text-container {
    position: absolute;
    top: 30%;
    left: 10%;
    color: white;
    width: 30%;
}

@media (max-width: 1023px) {
    .svg-header-text-container {
        width: 80%;
        text-align: center;
        top: 30%;
    }
}

@media (max-width: 500px) {
    .svg-header-text-container {
        top: 22%;
    }
}

@media (max-width: 350px) {
    .svg-header-text-container {
        top: 10%;
    }
}*/
/*
.svg-header-image {
    color: white !important;
    width: 26%;
    height: auto;
    position: absolute;
    visibility: visible;
    left: 53%;
    top: 15%;
}

@media (min-width: 1921px) {
    .svg-header-image {
        width: 500px;
    }
}

@media (max-width: 1023px) {
    .svg-header-image {
        display: none;
    }

    .header-container {
        width: 80%;
    }
}

@media (max-width: 1300px) {
    .svg-header-image {
        left: 55%;
        top: 18%;
    }
}
*/

.svg-header-image-home {
    color: white !important;
    width: 33%;
    height: auto;
    position: absolute;
    visibility: visible;
    left: 53%;
    top: 11%;
}

@media (min-width: 1921px) {
    .svg-header-image-home {
        width: 500px;
    }
}

@media (max-width: 1023px) {
    .svg-header-image-home {
        display: none;
    }

    .header-container {
        width: 80%;
    }
}

@media (max-width: 1300px) {
    .svg-header-image-home {
        left: 55%;
        top: 18%;
    }
}

/*
@media (max-width: 768px) {
    #gradient-canvas {*/
        /*clip-path: polygon(100% 0%, 0% 0%, 0.00% 97.27%, 2.00% 97.47%, 4.00% 97.63%, 6.00% 97.76%, 8.00% 97.87%, 10.00% 97.94%, 12.00% 97.99%, 14.00% 98.00%, 16.00% 97.98%, 18.00% 97.94%, 20.00% 97.86%, 22.00% 97.75%, 24.00% 97.61%, 26.00% 97.45%, 28.00% 97.25%, 30.00% 97.02%, 32.00% 96.76%, 34.00% 96.48%, 36.00% 96.16%, 38.00% 95.81%, 40.00% 95.44%, 42.00% 95.04%, 44.00% 94.60%, 46.00% 94.14%, 48.00% 93.66%, 50.00% 93.14%, 52.00% 92.60%, 54.00% 92.03%, 56.00% 91.43%, 58.00% 90.81%, 60.00% 90.16%, 62.00% 89.49%, 64.00% 88.79%, 66.00% 88.07%, 68.00% 87.32%, 70.00% 86.55%, 72.00% 85.76%, 74.00% 84.94%, 76.00% 84.11%, 78.00% 83.25%, 80.00% 82.37%, 82.00% 81.47%, 84.00% 80.54%, 86.00% 79.60%, 88.00% 78.65%, 90.00% 77.67%, 92.00% 76.67%, 94.00% 75.66%, 96.00% 74.64%, 98.00% 73.59%, 100.00% 72.53%);*/
        /*clip-path: none;
        transform: skewY(-14deg);
        transform-origin: 0;
    }
}*/

@media (max-width: 1404px) {
    #gradient-canvas {
        /*clip-path: polygon(100% 0%, 0% 0%, 0.00% 97.27%, 2.00% 97.47%, 4.00% 97.63%, 6.00% 97.76%, 8.00% 97.87%, 10.00% 97.94%, 12.00% 97.99%, 14.00% 98.00%, 16.00% 97.98%, 18.00% 97.94%, 20.00% 97.86%, 22.00% 97.75%, 24.00% 97.61%, 26.00% 97.45%, 28.00% 97.25%, 30.00% 97.02%, 32.00% 96.76%, 34.00% 96.48%, 36.00% 96.16%, 38.00% 95.81%, 40.00% 95.44%, 42.00% 95.04%, 44.00% 94.60%, 46.00% 94.14%, 48.00% 93.66%, 50.00% 93.14%, 52.00% 92.60%, 54.00% 92.03%, 56.00% 91.43%, 58.00% 90.81%, 60.00% 90.16%, 62.00% 89.49%, 64.00% 88.79%, 66.00% 88.07%, 68.00% 87.32%, 70.00% 86.55%, 72.00% 85.76%, 74.00% 84.94%, 76.00% 84.11%, 78.00% 83.25%, 80.00% 82.37%, 82.00% 81.47%, 84.00% 80.54%, 86.00% 79.60%, 88.00% 78.65%, 90.00% 77.67%, 92.00% 76.67%, 94.00% 75.66%, 96.00% 74.64%, 98.00% 73.59%, 100.00% 72.53%);*/
        clip-path: none;
        transform: skewY(-14deg);
        transform-origin: 0;
    }

    .header-container h1 {
        font-size: 43px;
    }
}
/*
@media (max-width: 412px) {
    #gradient-canvas {
        transform: skewY(0deg);
    }
}*/

@media (max-width: 767px) {
    #gradient-canvas {
        transform: skewY(0deg);
    }
}

.globe-header-button {
    background-color: white;
    color: #4c57f6;
    border: none;
    border-radius: 15px;
    width: 50%;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 23px;
    transition: all 0.5s;
    cursor: pointer;
}

.globe-header-button-span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.globe-header-button-span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.globe-header-button:hover .globe-header-button-span {
    padding-right: 25px;
}

.globe-header-button:hover .globe-header-button-span:after {
    opacity: 1;
    right: 0;
}

.globe-header-button-text {
    color: inherit;
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
}

.globe-header-button-text:hover {
    color: #4c57f6;
}