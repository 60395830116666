.detail-card-container-section {
    margin-top: 100px;
}

.detail-card-container {
    width: 100%;
    max-width: 1300px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.detail-card-h3 {
    color: black;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 400;
}

.detail-card-p {
    font-size: 18px;
    color: black;
    text-align: center;
    font-weight: 300;

    &.small {
        font-size: 18px;
    }
}

.detail-card-container .detail-card {
    display: block;
    position: relative;
    max-width: 380px;
    height: 290px;
    max-height: 300px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 26px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border-radius: 50px;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -30px;
        right: -30px;
        /*background: linear-gradient(to bottom right, #4c57f6, #2f8bc1, #ccbeee);*/
        background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
        height: 50px;
        width: 50px;
        border-radius: 50px;
        transform: scale(1);
        transform-origin: 50% 50%;
        transition: transform 0.25s ease-out;
    }

    &:hover:before {
        transform: scale(21);
    }
}

.detail-card-container .detail-card:hover .detail-card-h3,
.detail-card-container .detail-card:hover .detail-card-p {
    color: #ffffff;
    transition: all 0.3s ease-out;
}

.detail-card-text {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 110px;
}

.detail-card-text-h1 {
    font-weight: 400;
}

.detail-card-p {
    text-align: left;
}