/* white background
.quiz-table-container {
    width: 80%;
    margin: 0 auto;
}

.quiz-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
}

.quiz-table-header,
.quiz-table-row {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.quiz-title-header,
.quiz-score-header,
.quiz-title-cell,
.quiz-score-cell {
    flex-basis: 50%;
    text-align: center;
    padding: 10px;
    border-right: 1px solid #ccc;
}

.quiz-score-header,
.quiz-score-cell {
    border-right: none;
}

.quiz-table-row:hover {
    background-color: #f0f0f0;
}
*/

/* non-dropdown version,
this works with the commented out return in the jsx
.quiz-table-container {
    width: 80%;
    margin: 0 auto;
    background-color: #1a1a2e;
    color: #e0e1dd;
    padding: 20px;
    border-radius: 15px;
}

.quiz-table {
    border: 1px solid #16213e;
    border-collapse: collapse;
}

.quiz-table-header,
.quiz-table-row {
    display: flex;
    border-bottom: 1px solid #16213e;
}

.quiz-title-header,
.quiz-score-header,
.quiz-title-cell,
.quiz-score-cell {
    flex-basis: 50%;
    text-align: center;
    padding: 10px;
    border-right: 1px solid #16213e;
}

.quiz-score-header,
.quiz-score-cell {
    border-right: none;
}

.quiz-table-row:hover {
    background-color: #0f3460;
}

.dashboard-button {
    background-color: white;
    color: #4c57f6;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 35%;
    text-align: center;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .dashboard-button {
        width: 100%;
    }
}

.dashboard-button:hover {
    background: linear-gradient(to bottom right,
                #4c57f6,
                #449ce4,
                #4c57f6);
    color: white;
}

.dashboard-header {
    color: #e0e1dd;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.list-container {
    background-color: #0b0b2b;
    padding: 20px;
    min-height: 100vh;
}

.dashboard-navigation-buttons {
    position: relative;
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.dashboard-back-button-container {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 20px;
}

.dashboard-home-button {
    text-decoration: none;
    font-size: 30px;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.dashboard-home-button:hover {
    color: white;
    background: none;
}

.dashboard-home-button-container {
    padding-top: 5px;
    padding-bottom: 20px;
}

.dashboard-back-button {
    text-decoration: none;
    background-color: white;
    color: #4c57f6;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-align: center;
    margin: auto;
}

.dashboard-back-button:hover {
    background: linear-gradient(to bottom right,
                #4c57f6,
                #449ce4,
                #4c57f6);
    color: white;
}
*/























/* the only css used for the dashboard is below */

.list-dashboard-section {
    /*background-color: #0b0b2b;*/
    background-color: #0A0A0A;
    padding: 20px;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
}

.list-dashboard-header {
    color: white;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: 400;
}

.list-modules-container {
    background-color: #1a1a2e;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    margin: 0 auto;
}

@media only screen and (max-width: 768px) {
    .list-modules-container {
        width: 95%;
    }
}

.list-module {
    background-color: #1a1a2e;
    border-bottom: 1px solid #16213e;
    padding: 15px;
    cursor: pointer;
    color: white;
    display: block;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
    border-radius: 25px;
}

.list-module:hover {
    background-color: #0f3460;
}

.list-module-open {
    background-color: #0f3460;
}

.list-module-open .list-module-title-wrapper,
.list-module-open .list-module-description {
    background-color: #0f3460;
}

.list-module:not(.list-module-open):hover {
    background-color: #0f3460;
}

.list-module-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.list-module-title {
    font-weight: 400;
    font-size: 1.2em;
    color: white;
    flex-grow: 1;
}

.list-caret {
    color: white;
    transition: transform 0.3s ease;
}

.list-module-description {
    background-color: #16213e;
    color: white;
    padding-top: 10px;
    font-size: 0.95em;
    line-height: 1.4;
    display: none;
    width: 100%;
}

.module-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two equal columns */
    margin-top: 10px;
    background-color: #1a1a2e;
    /* Uniform background color for the table */
    border-collapse: collapse;
    /* Removes space between borders of adjacent cells */
    border: 1px solid white;
    /* White border for the entire table */
}

.module-table-header {
    font-weight: 400;
    padding: 10px;
    background-color: #1a1a2e;
    /* Uniform background color for headers */
    color: white;
    /* Light text color */
    text-align: left;
    border: 1px solid white;
    font-size: 1.2em;
}

.module-table-cell {
    padding: 10px;
    background-color: #1a1a2e;
    /* Uniform background color for cells */
    border: 1px solid white;
    /* White border for each cell */
}

.module-table-row {
    display: contents;
}

@media only screen and (max-width: 768px) {
    .module-table {
        display: block;
        /* Change table layout to block for stacking on small screens */
    }

    .module-table-header {
        display: block;
        font-weight: bold;
        color: white;
        /* Light color for visibility */
        padding-bottom: 10px;
        /* Spacing after header */
    }

    .module-table-cell {
        display: block;
        /* Stack cells within each row */
        padding-bottom: 10px;
        /* Spacing after each cell */
    }

    .list-dashboard-button {
        width: 100%;
        /* Full width for buttons */
        margin-bottom: 10px;
        /* Space between buttons */
    }
}



.list-module-open .list-module-description {
    display: block;
}

.list-dashboard-button {
    background-color: #1a1a2e;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 50%;
    text-align: center;
    margin: 10px auto;
    display: block;
}

.list-dashboard-button:hover {
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #4c57f6);
    color: white;
}

@media only screen and (max-width: 768px) {
    .list-dashboard-button {
        width: 100%;
    }
}

.list-module-open .list-module-description {
    display: block;
}

.list-dashboard-button {
    /*background-color: #1a1a2e;*/
    background-color: #0f3460;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    cursor: pointer;
    width: 90%;
    text-align: center;
    margin: 10px auto;
    display: block;
    font-weight: 400;
}

.list-dashboard-button:hover {
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #4c57f6);
    color: white;
}

@media only screen and (max-width: 768px) {
    .list-dashboard-button {
        width: 100%;
    }
}

.dashboard-navigation-buttons {
    position: relative;
        text-align: center;
        padding-bottom: 20px;
}

.dashboard-back-button {
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    text-decoration: none;
    background-color: #1a1a2e;
    transition: background-color 0.3s ease;
    margin-top: 5px;
}

.dashboard-home-button {
    text-decoration: none;
    font-size: 2.2em;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.dashboard-home-button:hover {
    color: white;
    background: none;
}

.dashboard-back-button:hover {
    background-color: #0f3460;
    color: white;
}

.dashboard-back-button {
    position: absolute;
        left: 10%;
        top: 0;
}

@media only screen and (max-width: 768px) {
    .dashboard-back-button {
        margin-right: 50%;
    }
}
