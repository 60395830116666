/*#gradient-canvas-pricing {
    width: 100%;
    height: 100%;
    --gradient-color-1: #449ce4;
    --gradient-color-2: #2f8bc1;
    --gradient-color-3: #ccbeee;
    --gradient-color-4: #4c57f6;
    z-index: 1;
    min-height: 100vh;
}*/

/* General Styling for the Pricing Page */
.pricing-container {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    /* Example background color */
}

.pricing-background {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    /* Gradient background */
}

.content-container {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    text-align: center;
    margin: 20px 0;
    /* Space between sections */
}

.canvas-style {
    position: absolute;
    width: 100%;
    height: 100%;
    --gradient-color-1: #449ce4;
    --gradient-color-2: #2f8bc1;
    --gradient-color-3: #ccbeee;
    --gradient-color-4: #4c57f6;
    z-index: -10;
}

.pricing-card {
    position: relative;
    background-color: #ffffff;
    /* Crisp white background */
    padding: 50px;
    border-radius: 20px;
    /* Slightly rounded edges for a softer look */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
    /* Subtle shadow */
    width: 400px;
    /* Adjusted width for better layout */
    max-width: 95%;
    /* Ensure it doesn't get too wide on small screens */
    z-index: 1;
    transition: box-shadow 0.3s ease;
    /* Smooth transition for hover effects */
    backdrop-filter: blur(10px);
    /* Soft blur effect */
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* Subtle border */
    text-align: center;
}

.pricing-card:hover {
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.plan-name {
    font-size: 2.0em;
    color: #4c57f6;
    /* Matching color with canvas gradient */
    margin-bottom: 15px;
    font-weight: 400;
    /* Stronger font weight */
}

.plan-price {
    font-size: 2.5em;
    color: #4c57f6;
    /* Bold and vibrant color */
    margin-bottom: 0px;
    font-weight: 400;
    /* Semi-bold font weight */
}

.plan-description {
    font-size: 1.2em;
    margin-bottom: 30px;
    color: #333;
    /* Darker color for contrast */
    line-height: 1.6;
    /* Improved line spacing for readability */
}

.plan-features {
    list-style: none;
    /* Removing default list style */
    padding: 0;
    text-align: left;
    margin-bottom: 15px;
    font-size: 1.1em;
}

.plan-features li {
    margin-bottom: 12px;
    font-size: 1em;
    color: #555;
    position: relative;
    /* Required for custom bullet points */
    padding-left: 20px;
    /* Space for custom bullet points */
}

.plan-features li::before {
    content: '•';
    /* Unicode bullet character */
    color: #4c57f6;
    /* Matching color with canvas gradient */
    position: absolute;
    left: 0;
    /* Align with the start of the text */
    top: 50%;
    transform: translateY(-50%);
}

.subscribe-button {
    background-color: #4c57f6;
    color: white;
    border: none;
    padding: 15px 25px;
    border-radius: 8px;
    /* Rounded button edges */
    cursor: pointer;
    font-size: 1.1em;
    /* Medium font weight */
    letter-spacing: 0.5px;
    /* Slight letter spacing */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.subscribe-button:hover {
    background-color: #3b4ca4;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 820px) {
    .pricing-card {
        padding: 25px;
        width: 100%;
    }

    .plan-name {
        font-size: 2.1em;
    }

    .plan-price {
        font-size: 2.8em;
    }

    .original-price {
        font-size: 1.3em !important;
    }

    .plan-description {
        font-size: 1.1em;
    }

    .plan-features li {
        font-size: 0.9em;
    }

    .subscribe-button {
        padding: 10px 15px;
        font-size: 0.9em;
    }
}

/* Key Benefits Section */
.key-benefits {
    background-color: white;
    /* Semi-transparent background */
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 40px;
    /* Spacing between sections */
}

.benefits-title {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 25px;
    font-weight: 400;
}

.benefits-list {
    list-style: none;
    padding: 0;
    text-align: left;
    font-size: 1.1em;
}

.benefits-list li {
    margin-bottom: 15px;
    font-size: 1em;
    color: #555;
    position: relative;
    padding-left: 20px;
}

.benefits-list li::before {
    content: '✓';
    /* Unicode checkmark */
    color: #28a745;
    /* Green checkmark */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
}

@media (max-width: 820px) {
    /* ... existing responsive styles ... */

    .content-container {
        padding: 10px;
    }

    .key-benefits {
        padding: 20px;
    }

    .benefits-title {
        font-size: 1.6em;
    }

    .benefits-list li {
        font-size: 0.9em;
    }
}

.cards-container {
    display: flex;
    justify-content: space-around;
    /* Evenly space out the cards */
    align-items: flex-start;
    /* Align cards at the top */
    gap: 40px;
    /* Space between cards */
    width: 100%;
    max-width: 1200px;
    /* Adjust the container width as needed */
    margin-top: 120px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    flex-wrap: wrap;
    /* Wrap cards on smaller screens */
}

.pricing-card {
    flex-basis: 40%;
    /* Width for the pricing card */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Distribute space between items */
    background-color: white;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    margin-bottom: 40px;
}

.key-benefits {
    flex-basis: 60%;
    /* More width for key benefits card */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Distribute space between items */
    background-color: white;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    margin-bottom: 40px;
}

.pricing-card {
    flex-basis: 45%;
    /* Width for the pricing card */
}

.key-benefits {
    flex-basis: 50%;
    /* More width for key benefits card */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 820px) {
    .cards-container {
        flex-direction: column;
        align-items: stretch;
        /* Stretch cards to full width */
        margin-top: 150px;
        margin-bottom: 150px;
        gap: 20px;
        /* Adjust gap for smaller screens */
    }

    .pricing-card,
    .key-benefits {
        flex-basis: 100%;
        width: 95%;
        margin: 0 auto;
        /* Full width on smaller screens */
    }

    .modules-summary-pricing {
        margin: 0 auto;
        width: 95%;
    }
}

.content-container,
.pricing-card,
.key-benefits {
    z-index: 10;
    /* Higher than the canvas */
}

.alert-position {
    position: absolute;
    top: 70px;
    /* Adjust based on your navbar's height */
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    z-index: 100;
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
}

@media (max-width: 800px) {
.alert-position {
    width: 95%;
    }
}

.close-button-pricing {
    border: none;
    background: none;
    margin-left: 20px;
}




.modules-summary-pricing {
    flex-basis: 45%;
    /* Adjust this value to control the width of the module summary relative to the pricing card */
    max-width: 95%;
    /* Ensure it doesn't get too wide on small screens */
    background-color: #ffffff;
    /* Match the background of the pricing card for consistency */
    padding: 20px;
    /* Match the padding for consistency */
    border-radius: 20px;
    /* Rounded edges like the pricing card */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
    /* Shadow effect like the pricing card */
    display: flex;
    flex-direction: column;
    /* Stack content vertically */
    margin-bottom: 100px;
}

.modules-list-pricing {
    width: 100%;
    /* This ensures the list takes up the full width of its parent .modules-summary */
    max-width: 800px;
    /* Adjust this value to match the desired fixed width of the list */
    margin: 0 auto;
    /* Centers the list within the .modules-summary */
    overflow: hidden;
    /* Prevents content from expanding the container's size */
}

.modules-title-pricing {
    font-size: 1.5em;
    /* Adjust as needed */
    margin-bottom: 20px;
    /* Space below the title */
    color: #4c57f6;
    /* A color that matches the theme */
    text-align: center;
}

.module-summary-item-pricing {
    font-size: 0.95em;
    /* Slightly smaller font size for the items */
    margin-bottom: 10px;
    /* Space between items */
    color: #666;
    /* Darker font color for readability */
    line-height: 1.4;
    /* Line height for better readability */
}

/* Ensure responsiveness */
@media (max-width: 820px) {
    .modules-summary-pricing {
        flex-basis: 100%;
        /* Take the full width on smaller screens */
        margin-top: 20px;
        /* Space it from the pricing card above it */
    }
}

.original-price {
    text-decoration: line-through;
    /* Crosses out the original price */
    color: #666;
    /* A lighter color to differentiate from the discounted price */
    font-size: 1.2em;
    margin-bottom: 0px;
}

/*
.prices {
    display: flex;
    align-items: baseline;
}*/

.prices {
    display: flex;
    flex-direction: column;
    /* Stack price and access duration vertically */
    align-items: center;
    /* Center-align the price and access duration */
    margin-bottom: 20px;
    /* Space before the description */
}
/*
.access-duration {
    font-size: 1.3rem;
    color: #666;
}*/

.access-duration {
    font-size: 1.1rem;
    /* Keep your existing font size */
    color: #333;
    /* Standard text color for clarity */
    padding: 5px 10px;
    /* Adds space inside the border */
    border: 2px solid #4c57f6;
    /* Solid border with a color that matches your theme */
    border-radius: 10px;
    /* Rounded corners for the border */
    margin-top: 10px;
    /* Space above the text to separate it from other elements */
    display: inline-block;
    /* Ensures the border wraps tightly around the text */
}

.current-price-container {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    gap: 7px;
}

.pricing-notice {
    font-size: 1.0em;
    color: #6c757d;
    margin-bottom: 25px;
    /* Space above the button */
    text-align: center;
    /* Keeps it centered under the list */
}
