.comparison-table {
    max-width: 1250px;
    margin: 0 auto;
    overflow-x: auto;
    border-radius: 50px;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px;
    table-layout: fixed;
    min-width: 800px;
    background-color: #f2f8f9;
}

th,
td {
    padding: 10px;
    text-align: center;
    border: none;
    font-size: 20px;
}

@media (max-width:481px) {
    th,td {
        font-size: 17px;
    }
}

th {
    background-color: white;
    font-weight: bold;
}

.checkmark {
    color: green;
}

.cross {
    color: #F44336;
}

.comparison-text {
    text-align: center;
    margin-bottom: 100px;
}

.comparison-text h1 {
    font-weight: 400;
}

.comparison-container {
    margin-top: 100px;
    margin-bottom: 100px;
}

.custom-color {
    /*background: linear-gradient(to bottom right, #4c57f6, #2f8bc1, #ccbeee);*/
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    color: white;
    border-radius: 50px;
}

.non-custom-color {
    background-color: #f2f8f9;
}

.comparison-table th, td {
    font-weight: 400;
}
