.success-page {
    position: relative;
}

.gradient-canvas-success {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    z-index: 1;
    min-height: 100vh;
}

.success-container {
    width: 80%;
    margin: 0 auto;
    background-color: white;
    border-radius: 25px;
    text-align: center;
    z-index: 2;
    position: absolute;
    margin-top: 200px;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 40px;
    padding-bottom: 40px;
}

.success-button {
    text-decoration: none;
    background-color: #4c57f6;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 1em;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s;
    font-size: 1.1em;
}

.success-button:hover {
    background-color: #449ce4;
    color: white;
}

.success-h1 {
    margin-bottom: 25px;
}

.success-p {
    margin-bottom: 35px;
    font-size: 1.1em;
}

.screen-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    display: flex;
    align-items: center;
}

.loader {
    width: 100%;
    height: 15px;
    text-align: center;
}

.loading-dot {
    position: relative;
    width: 15px;
    height: 15px;
    margin: 0 2px;
    display: inline-block;
}

.loading-dot:first-child:before {
    animation-delay: 0ms;
}

.loading-dot:first-child:after {
    animation-delay: 0ms;
}

.loading-dot:last-child:before {
    animation-delay: 200ms;
}

.loading-dot:last-child:after {
    animation-delay: 200ms;
}

.loading-dot:before {
    content: "";
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: blue;
    animation-name: dotHover;
    animation-duration: 900ms;
    animation-timing-function: cubic-bezier(.82, 0, .26, 1);
    animation-iteration-count: infinite;
    animation-delay: 100ms;
    background: white;
    border-radius: 100%;
}

.loading-dot:after {
    content: "";
    position: absolute;
    z-index: -1;
    background: black;
    box-shadow: 0px 0px 1px black;
    opacity: .20;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: -2px;
    border-radius: 100%;
    animation-name: dotShadow;
    animation-duration: 900ms;
    animation-timing-function: cubic-bezier(.82, 0, .26, 1);
    animation-iteration-count: infinite;
    animation-delay: 100ms;
}

@keyframes dotShadow {
    0% {
        transform: scaleX(1);
    }

    50% {
        opacity: 0;
        transform: scaleX(.6);
    }

    100% {
        transform: scaleX(1);
    }
}

@keyframes dotHover {
    0% {
        top: 0px;
    }

    50% {
        top: -50px;
        transform: scale(1.1);
    }

    100% {
        top: 0;
    }
}

.success-page-nav-button {
    text-decoration: none;
    color: white;
    font-size: 1.5em;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

.success-page-nav-button:hover {
    color: white;
}