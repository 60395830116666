.quiz-container {
    /*background-color: #0b0b2b;*/
    background-color: #0A0A0A;
    /* Mainly for the outside container */
    color: #e0e1dd;
    /* Light text color for readability */
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.quiz-content {
    width: 80%;
    margin: 0 auto;
}

@media (max-width: 690px) {
    .quiz-content {
        width: 95%;
    }
}

.quiz-header {
    color: white;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
}

.quiz-question {
    position: relative;
    background-color: #1a1a2e;
    padding: 15px;
    border-radius: 8px;
    margin-top: 50px;
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;
    color: white;
    font-size: 1.1em;
}

.quiz-progress {
    position: absolute;
    left: 11%;
    margin-top: 55px;
}

@media (max-width: 810px) {
    .quiz-progress {
        left: 0%;
        position: relative;
        text-align: center;
        margin-top: 10px;
        width: 100% !important;
    }
}

.quiz-answer-button {
    background-color: #0f3460;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    display: block;
    /* Make buttons stack vertically */
    width: 30%;
    /* Full width of the container */
    box-sizing: border-box;
    /* Include padding in the width calculation */
    margin: 10px 0;
}

@media (max-width: 768px) {
    .quiz-answer-button {
        display: block;
        /* Make buttons stack vertically */
        width: 100%;
        /* Full width of the container */
        box-sizing: border-box;
        /* Include padding in the width calculation */
        margin: 10px 0;
        /* Adjust margin for vertical stacking */
    }
}

.quiz-question-text {
    font-size: 1.5em;
}

.quiz-answer-button:hover {
    background-color: #16213e;
}

.quiz-answer-button.selected {
    background-color: #4c57f6;
    /* Highlight for selected answer */
}

.quiz-submit-button {
    background-color: #4c57f6;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 20px;
    display: inline-block;
    width: 200px;
    margin-left: auto;
    margin-right: 0;
    /* Aligns the button to the right */
    font-size: 1.1em;
}

.quiz-submit-button:disabled {
    background-color: #16213e;
    cursor: not-allowed;
}

.quiz-submit-button:hover {
    background-color: #449ce4;
}

.button-container {
    text-align: right;
    padding-top: 20px;
}

/* Add styles for the navigation buttons */
.quiz-navigation {
    display: flex;
    justify-content: flex-start;
    /* Align items to the start */
    margin-bottom: 30px;
    position: relative;
    /* Needed for absolute positioning of home button */
    margin-bottom: 50px;
}

.quiz-back-button {
    background-color: #1a1a2e;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    margin-left: calc((100% - 80%) / 2);
    margin-top: 5px;
    /* Align with the left side of quiz-content */
}

.quiz-home-button {
    text-decoration: none;
    font-size: 2.2em;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: absolute;
    /* Position the home button absolutely */
    left: 50%;
    /* Move to the center */
    transform: translateX(-50%);
    /* Offset by half its width to truly center it */
}

.quiz-home-button:hover {
    color: white;
}

.quiz-back-button:hover {
    background-color: #0f3460;
    color: white;
}

.quiz-score {
    text-align: center;
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
}

.quiz-back-to-dashboard-button {
    display: block;
    margin: 20px auto;
    text-align: center;
    width: 20%;
    border-radius: 25px;
    color: white;
    background-color: #4c57f6;
    transition: background-color 0.3s ease;
    font-size: 1.1em;
}

.quiz-back-to-dashboard-button:hover {
    background-color: #449ce4;
}

@media (max-width: 767px) {
    .quiz-back-to-dashboard-button {
        width: 70%;
    }
}
