.gradient-privacy-page {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    z-index: 1;
    min-height: 100vh;
}

.privacy-page {
    display: flex;
    /* Using Flexbox */
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
    position: relative;
    min-height: 100vh;
    /* Ensure it covers at least the viewport height */
}

.privacy-page-content {
    z-index: 2;
    position: relative;
    /* Changed to relative */
    background-color: white;
    color: black;
    width: 80%;
    margin: 150px auto 0;
    /* Center horizontally */
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    margin-bottom: 150px;
}

@media (max-width: 768px) {
    .privacy-page-content {
        z-index: 2;
        position: relative;
        /* Changed to relative */
        background-color: white;
        color: black;
        width: 95%;
        margin: 150px auto 0;
        /* Center horizontally */
        padding: 30px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 40px;
        margin-bottom: 150px;
    }
}