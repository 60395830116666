/** {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    max-width: 1170px;
    margin: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

ul {
    list-style: none;
}

.footer {
    background-color: #f2f3f6;
    padding: 70px 0;
}

.footer-col {
    width: 25%;
    padding: 0 15px;
}

.footer-col h4 {
    font-size: 18px;
    color: #4c57f6;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
    text-align: center;
}

.footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #4c57f6;
    height: 2px;
    box-sizing: border-box;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
}

.footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-link {
    padding-right: 30px;
}

.footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 300;
    color: #4c57f6;
    display: block;
    transition: all 0.3s ease;
    text-align: center;
}

.footer-col ul li a:hover {
    color: rgb(18, 20, 105);
    padding-left: 8px;
}

.footer-col .social-links {
    text-align: center;
}

.footer-col .social-links a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #4c57f6;
    transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
    color: #ffffff;
    background-color: #4c57f6;
}

@media(max-width: 767px) {
    .footer-col {
        width: 50%;
        margin-bottom: 30px;
    }
}

@media(max-width: 574px) {
    .footer-col {
        width: 100%;
    }
}

.footer h4 {
    font-weight: 400;
}*/

.footer {
    background-color: #f2f3f6;
    color: #4c57f6;
    text-align: center;
    padding: 20px 0;
}

.footer-link {
    color: #4c57f6;
    text-decoration: none;
    display: block;
    margin-bottom: 15px;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #449ce4;
}

.footer-company-name {
    margin-top: 20px;
    font-size: 14px;
}

.footer-social-links {
    text-align: center;
}

.footer-social-link {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #4c57f6;
    transition: all 0.5s ease;
}

.footer-social-link:hover {
    color: #ffffff;
    background-color: #4c57f6;
}

.contact-us-link {
    cursor: pointer;
}