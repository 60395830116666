.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin: auto;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    position: relative;
    height: 200px;
}

.close-button {
    position: absolute;
    top: 0px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5em;
}

.contact-us-modal-button {
    background-color: #4c57f6;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.contact-us-modal-button:hover {
    background-color: #449ce4;
}

/*
.reventt-color {
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: block;
    margin-top: 8px;
    margin-bottom: 20px;
}*/