.card-box {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-bottom: 50px;
    margin: auto;
    min-height: 500px;
}

@media (min-width: 1380px) {
    .card-box {
        width: 78%;
        height: 400px;
    }
}

@media (min-width: 1784px) {
    .card-box {
        width: 60%;
        height: 400px;
    }
}

@media (max-width: 767px) {
    .card-box {
        height: auto;
    }
}

.content {
    max-width: 1300px;
    width: 100%;
    padding: 0 4%;
    padding-top: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .content {
        padding-top: 80px;
        flex-direction: column;
    }
}

.card {
    width: 100%;
    max-width: 312px;
    min-width: 200px;
    height: 250px;
    /*background-color: #2f8bc1;*/
    /*background: linear-gradient(to bottom right, blue, pink);*/
    /*main background*/
    /*background: linear-gradient(to bottom right, #4c57f6, #2f8bc1, #ccbeee);*/
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    /*background: linear-gradient(to bottom right, #2641c2, #4894cd,rgb(168, 191, 254));*/
    margin: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease;
    border-radius: 20px;
    border: none;
    cursor: pointer;
}

@media (max-width: 600px) {
    .card {
        max-width: 300px;
        height: 225px;
    }
}

.icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*color: #449ce4;*/
    color: #4c57f6;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 30px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
    font-weight: 400;
}

.card:hover {
    height: 300px;
}

.card:hover .info {
    height: 90%;
}

.card:hover .text {
    transition: all 0.3s ease;
    opacity: 1;
    max-height: 40px;
}

.card:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
}

.card:hover .icon i {
    background: linear-gradient(90deg, #4c57f6, #4c57f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    transition: all 0.3s ease;
}

.card-animation-text {
    text-align: center;
    padding-top: 70px;
}

.card-animation-text h1 {
    font-weight: 400;
}

.catagory-button {
    text-align: center;
    padding-bottom: 50px;
}
