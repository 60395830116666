.lesson-page {
    /*background-color: #0b0b2b;*/
    background-color: #0A0A0A;
    width: 100%;
}

.lesson-container {
    width: 85%;
    /* Dark background color */
    color: white;
    /* Light text color for readability */
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
    .lesson-container {
        width: 95%;
    }
}

.lesson-container ul,
.lesson-container ol {
    padding-left: 20px;
    /* Adjusting list padding */
}

.lesson-container li {
    margin-bottom: 10px;
    /* Spacing between list items */
}
/*
.lesson-button {
    background-color: #1a1a2e;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    display: block;
    margin: 10px 0;
}

.lesson-button:hover {
    background-color: #0f3460;
}*/

.lesson-button {
    background-color: #2d4059;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    display: block;
    margin: 10px 0;
}

.lesson-button:hover {
    /*background-color: #4c57f6;*/
    /*background-color: #0f3460;*/
    background-color: #34495e;
}

.lesson-h2, .lesson-h3 {
    margin-top: 50px;
    margin-bottom: 20px;
}

.lesson-p {
    line-height: 2.3;
}

.lesson-container pre {
    /*background-color: rgb(59,63,74) !important;*/
    background-color: #2d4059 !important;
}

.lesson-container pre:hover {
    background-color: #34495e !important;
}

.lesson-container code {
    border-radius: 5px;
}

.lesson-container code:hover {
    background-color: #34495e !important;
}

.lesson-link {
    color: rgb(58, 101, 255) !important;
    text-decoration: none;
}

.lesson-link:hover {
    color: white !important;
}


.lesson-navigation-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    padding-top: 30px;
}

.lesson-home-button {
    position: absolute; /* Position absolute to take out of flex flow */
    left: 50%; /* Center relative to the parent */
    transform: translateX(-50%); /* Precisely center the button */
    text-decoration: none;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 2.2em;
}

.lesson-home-button:hover {
    color: white;
}

.lesson-back-button {
    margin-left: 120px;
    text-decoration: none;
    background-color: #2d4059;
    /* Matching the color scheme */
    color: white;
    /* White text for contrast */
    padding: 10px 20px;
    /* Adequate padding for a button */
    border-radius: 10px;
    /* Rounded corners */
    font-size: 1em;
    /* Font size that matches your design */
    border: none;
    /* Remove any default border */
    cursor: pointer;
    /* Cursor changes to a hand icon on hover */
    transition: background-color 0.3s ease, color 0.3s ease;
    /* Smooth transition for hover effect */
}

@media only screen and (max-width: 768px) {
    .lesson-back-button {
        margin-left: 25px;
    }
}

.lesson-back-button:hover {
    background-color: #34495e;
    color: white;
}

.end-of-lesson-dashboard-button-container {
    text-align: center;
    padding-bottom: 50px;
}

.end-of-lesson-dashboard-button {
    text-decoration: none;
    background-color: #2d4059;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1.2em;
}

.end-of-lesson-dashboard-button:hover {
    background-color: #34495e;
    color: white;
}
