.list-of-articles-container {
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    padding: 50px 0;
}

.list-of-articles-content {
    max-width: 800px;
    /* Adjust as needed */
    width: 100%;
    margin: auto;
    margin-top: 100px;
    background-color: white;
    padding: 40px;
    border-radius: 15px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    text-align: center;
}

.list-of-articles-h1 {
    color: #333;
    margin-bottom: 30px;
}

.list-of-articles-ul {
    list-style: none;
    padding: 0;
}

.list-of-articles-li {
    margin-bottom: 20px;
    /* Space between items */
}

.list-of-articles-link {
    color: #4c57f6;
    text-decoration: none;
    font-size: 1.2em;
    transition: color 0.3s;
}

.list-of-articles-link:hover {
    color: #449ce4;
    text-decoration: underline;
}

@media (max-width: 850px) {
    .list-of-articles-content {
        width: 93%;
    }
}