/*#gradient-canvas-login {
    width: 100%;
    height: 100%;
    --gradient-color-1: #449ce4;
    --gradient-color-2: #2f8bc1;
    --gradient-color-3: #ccbeee;
    --gradient-color-4: #4c57f6;
    z-index: 1;
    min-height: 100vh;
}*/

.login-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
}

.login-card {
    background-color: white;
    width: 50%;
    max-width: 500px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 250px;
    z-index: 2;
    text-align: center;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-text-header {
    margin-top: 10px;
    margin-bottom: 50px;
    font-weight: 400;
}

.login-card-content {
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (max-width: 768px) {
    .login-card {
        width: 90%;
    }
}