/* Article1.css */
.article-container {
    width: 100%;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    padding: 40px 0;
    /* Increased padding */
    min-height: 100vh;
}

.article-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 100px;
    background-color: white;
    padding: 40px;
    /* Increased padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}

@media (max-width: 1024px) {
    .article-content {
        width: 100%;
    }
}

.article-header .article-title {
    font-size: 2.0em;
    margin-bottom: 1.5em;
    /* Increased bottom margin */
    text-align: left;
    color: #333;
}

.article-section {
    margin-bottom: 80px;
}

.article-section-title {
    font-size: 1.7em;
    margin-bottom: 1em;
    /* Increased bottom margin */
    color: #4c57f6;
}

.article-section-paragraph {
    font-size: 1.2em;
    margin-bottom: 1.5em;
    /* Increased bottom margin */
    line-height: 1.8;
    /* Increased line-height */
    color: #666;
}

.article-section-list {
    padding-left: 20px;
    margin-bottom: 1.5em;
    /* Increased bottom margin */
}

.article-list-item {
    margin-bottom: 1em;
    font-size: 1.1em;
    /* Increased margin between list items */
}

.article-section-link {
    color: #4c57f6;
    margin-bottom: 1.5em;
    /* Increased bottom margin */
}

.article-section-link:hover {
    text-decoration: underline;
    color: #449ce4;
}







.article-advertisers-service-sec {
    background-color: #f5f5f5;
    border-radius: 30px;
    margin-top: 70px;
    margin-bottom: 50px;
}

.article-advertisers-service-sec .article-section-header .article-b-class-secondary {
    color: #4c57f6;
}

.article-advertisers-service-sec .article-section-header .article-sec-icon {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 auto;
}

.article-advertisers-service-sec .article-section-header .article-sec-icon::before,
.article-advertisers-service-sec .article-section-header .article-sec-icon::after {
    content: "";
    position: absolute;
    height: 1px;
    background: #333333;
    width: 50px;
}

.article-header-for-cards {
    margin-bottom: 50px;
}

.article-advertisers-service-sec .article-section-header .article-sec-icon::before {
    left: -70px;
    margin-top: -5.5px;
    top: 60%;
}

.article-advertisers-service-sec .article-section-header .article-sec-icon::after {
    right: -70px;
    margin-top: -5.5px;
    top: 60%;
}

.article-advertisers-service-sec .article-col {
    padding: 0 1em 1em 1em;
    text-align: center;
}

.article-advertisers-service-sec .article-service-card {
    width: 100%;
    height: 100%;
    padding: 2em 1.5em;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
    border-radius: 25px;
}

.article-advertisers-service-sec .article-service-card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    position: absolute;
    left: 0%;
    top: -97%;
    z-index: -2;
    transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
}

.article-card-h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
    z-index: 3;
}

.article-card-p {
    color: #575a7b;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    z-index: 3;
}

.article-advertisers-service-sec .article-icon-wrapper {
    background-color: #2c7bfe;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
    z-index: 3;
}

.article-advertisers-service-sec .article-service-card:hover:after {
    top: 0%;
}

.article-service-card .article-icon-wrapper {
    background-color: #ffffff;
    color: #4c57f6;
}

.article-advertisers-service-sec .article-service-card:hover .article-icon-wrapper {
    color: #0dcaf0;
}

.article-advertisers-service-sec .article-service-card:hover .article-card-h3 {
    color: #ffffff;
}

.article-advertisers-service-sec .article-service-card:hover .article-card-p {
    color: #f0f0f0;
}

.more-articles {
    margin-top: 20px;
    text-align: left;
    font-size: 1em;
}
