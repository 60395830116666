.modules-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background: #fff;
}

.module {
    border-bottom: 1px solid #eee;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 25px;
}

.module:last-child {
    border-bottom: none;
}

.module-title {
    font-weight: 500;
    font-size: 1.2em;
    color: #333;
    margin-bottom: 5px;
    transition: color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.caret {
    transition: transform 0.3s ease;
}

.module-description {
    padding-top: 10px;
    font-size: 0.95em;
    color: #666;
    line-height: 1.4;
    display: none;
}

.module.open .module-description {
    display: block;
}

.module:hover {
    background-color: #f9f9f9;
}

.module:hover .module-title {
    color: #4c57f6;
}

.module.open .module-description {
    display: block;
}

@media (max-width: 768px) {
    .modules-list {
        padding: 10px;
    }

    .module {
        padding: 10px;
    }

    .module-title {
        font-size: 1.1em;
    }
}

.modules-header-text {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 40px;
    font-weight: 400;
}

.module.open {
    background-color: #f9f9f9;
}

.module.open .module-title {
    color: #4c57f6;
}
