/*#gradient-canvas-subscription {
    width: 100%;
    height: 100%;
    --gradient-color-1: #449ce4;
    --gradient-color-2: #2f8bc1;
    --gradient-color-3: #ccbeee;
    --gradient-color-4: #4c57f6;
    z-index: 1;
    min-height: 100vh;
}*/

.view-subscription-container {
    /* Replace these hex values with your actual colors */
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    /* Dark Blue */
    /* Rest of your styles */
    min-height: 100vh;
    width: 100%;
    z-index: 1;
    position: relative;
}

.view-subscription-content {
    position: relative;
    z-index: 2;
    padding-top: 100px;
}

.subscription-card {
    background-color: white;
    margin: 0 auto;
    padding: 50px;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    text-align: center;
}

.view-subscription-content {
    position: relative;
    z-index: 2;
    padding-top: 100px;
    display: flex;
    justify-content: center;
}

.view-subscription-header-text {
    margin-bottom: 30px;
}

.view-subscription-subheader-text {
    margin-bottom: 30px;
}

.undo-cancellation-button {
    background-color: #5cb85c;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin: 10px;
    transition: background-color 0.3s;
}

.cancel-subscription-button {
    background-color: #d9534f;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin: 10px;
    transition: background-color 0.3s;
}

.undo-cancellation-button:hover {
    background-color: #4cae4c;
}

.cancel-subscription-button:hover {
    background-color: #c9302c;
}





.confirmation-dialog {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 80%;
    max-width: 400px;
    text-align: center;
}

.confirmation-dialog-text {
    font-size: 18px;
    color: #333;
    margin-bottom: 25px;
}

.dialog-confirm-button,
.dialog-cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 10px;
}

.dialog-confirm-button {
    background-color: #d9534f;
    color: white;
}

.dialog-confirm-button:hover {
    background-color: #c9302c;
}

.dialog-cancel-button {
    background-color: #f0f0f0;
    color: #333;
}

.dialog-cancel-button:hover {
    background-color: #e0e0e0;
}

.cancel-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
}

@media (max-width: 442px) {
    .dialog-confirm-button {
        background-color: #c9302c;
        margin-bottom: 10px;
    }

    .dialog-cancel-button {
        background-color: #f0f0f0;
        margin-top: 10px;
        color: #333;
    }
}