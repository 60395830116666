.globe-container {
    text-align: center;
    /*background: linear-gradient(to bottom right, #4c57f6, #2f8bc1, #ccbeee);*/
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    /*clip-path: polygon(100% 0%, 0% 0%, 0.00% 53.21%, 2.00% 52.60%, 4.00% 51.93%, 6.00% 51.22%, 8.00% 50.47%, 10.00% 49.72%, 12.00% 48.97%, 14.00% 48.25%, 16.00% 47.57%, 18.00% 46.94%, 20.00% 46.38%, 22.00% 45.90%, 24.00% 45.52%, 26.00% 45.24%, 28.00% 45.06%, 30.00% 45.00%, 32.00% 45.05%, 34.00% 45.22%, 36.00% 45.49%, 38.00% 45.86%, 40.00% 46.33%, 42.00% 46.88%, 44.00% 47.51%, 46.00% 48.19%, 48.00% 48.91%, 50.00% 49.65%, 52.00% 50.40%, 54.00% 51.15%, 56.00% 51.87%, 58.00% 52.54%, 60.00% 53.16%, 62.00% 53.71%, 64.00% 54.17%, 66.00% 54.54%, 68.00% 54.80%, 70.00% 54.96%, 72.00% 55.00%, 74.00% 54.93%, 76.00% 54.75%, 78.00% 54.46%, 80.00% 54.07%, 82.00% 53.58%, 84.00% 53.02%, 86.00% 52.38%, 88.00% 51.70%, 90.00% 50.97%, 92.00% 50.22%, 94.00% 49.47%, 96.00% 48.73%, 98.00% 48.02%, 100.00% 47.35%);*/
}

.globe {
    display: inline-block;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (max-width: 767px) {
    .globe-header-text {
        width: 90% !important;
    }

    .globe-header-button {
        width: 100% !important;
    }

    .globe-header-text {
        margin-top: 100px !important;
    }
}

@media (min-width: 768px) {
    .globe-container {
        max-height: 90vh;
    }

    .globe {
        width: 45%;
        float: right;
    }
}


@media (min-width: 1025px) {
    .globe {
        width: 55%;
        float: right;
    }
    .globe-header-text {
        padding-left: 100px;
    }
}

.globe-header-text {
    color: white;
    width: 40%;
    text-align: left;
    margin-top: 150px;
    display: inline-block;
}

.globe-header-text h1 {
    font-weight: 400;
}

.globe-header-text p {
    font-weight: 400;
    font-size: 18px;
}

.globe-header-button {
    background-color: white;
    color: #4c57f6;
    border: none;
    border-radius: 15px;
    width: 50%;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 23px;
    transition: all 0.5s;
    cursor: pointer;
}

.globe-header-button-span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.globe-header-button-span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.globe-header-button:hover .globe-header-button-span {
    padding-right: 25px;
}

.globe-header-button:hover .globe-header-button-span:after {
    opacity: 1;
    right: 0;
}

.globe-header-button-text {
    color: inherit;
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
}

.globe-header-button-text:hover {
    color: #4c57f6;
}



/* shifting color button
.globe-header-text button {
    color: white;
    width: 50%;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}




shifting color button
:root {
    --gradient-color-1: #449ce4;
    --gradient-color-2: #4c57f6;
    --mouse-x: 50;
    --mouse-y: 50;
}

button {
    background-size: 200% 200%;
    background-position: calc(var(--mouse-x) * 1%) calc(var(--mouse-y) * 1%);
    background-image: radial-gradient(circle, var(--gradient-color-1) 0%, var(--gradient-color-2) 100%);
    cursor: pointer;
    border: none;
    border-radius: 10px;
    font-size: 36px;
}*/

