/*#gradient-canvass {
    width: 100%;
    height: 100%;
        position: absolute;
            top: 0;
            left: 0;
    --gradient-color-1: #449ce4;
    --gradient-color-2: #2f8bc1;
    --gradient-color-3: #ccbeee;
    --gradient-color-4: #4c57f6;

    z-index: 1;
}*/

.overlay-container {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    margin-top: 70px;
}

.overlay-container {
    position: relative;
    /* This is important for absolute positioning context */
    min-height: 85vh;
    display: flex;
    /* Use flexbox for alignment */
    align-items: center;
    /* Align items vertically center */
    justify-content: center;
    /* Align items horizontally center */
    background-size: cover;
    background-position: center top;
    z-index: 0;
    /* Ensure this is lower than the overlay */
    background: linear-gradient(to bottom right, #4c57f6, #449ce4, #ccbeee);
}

@media only screen and (max-width: 767px) {
    .overlay-container {
        position: relative;
        /* This is important for absolute positioning context */
        min-height: 90vh;
        display: flex;
        /* Use flexbox for alignment */
        align-items: center;
        /* Align items vertically center */
        justify-content: center;
        /* Align items horizontally center */
        background-size: cover;
        background-position: center top;
        z-index: 0;
        /* Ensure this is lower than the overlay */
    }
    .overlay {
        min-height: 80vh;
        width: 90% !important;
    }
    .glassmorphism-button {
        width: 100% !important;
    }
    .overlay__title {
        font-size: calc(1.375rem + 1.5vw) !important;
    }
}

@media only screen and (max-width: 1200px) {
    .overlay {
        width: 90% !important;
    }
}

@media only screen and (max-width: 456px) {
    .overlay-container {
        position: relative;
        /* This is important for absolute positioning context */
        min-height: 90vh;
        display: flex;
        /* Use flexbox for alignment */
        align-items: center;
        /* Align items vertically center */
        justify-content: center;
        /* Align items horizontally center */
        background-size: cover;
        background-position: center top;
        z-index: 0;
        /* Ensure this is lower than the overlay */
        }
    
    .overlay {
        min-height: 80vh;
        width: 90% !important;
    } 
}

.overlay {
    position: absolute;
    /* Position overlay absolutely within overlay-container */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Centering the overlay */
    width: 100%;
    /* Adjust width as needed */
    max-width: 1140px;
    /* Adjust maximum width as needed */
    padding: 8rem 6rem;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.125);
    z-index: 2;
    max-height: 70vh;
    /* Higher than the canvas to ensure visibility */
}

.overlay__inner {
    max-width: 36rem;
}

.overlay__title {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 2rem;
    color: white;
}

.overlay__description {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 3rem;
    font-weight: 500;
    color: white;
}

@media only screen and (max-width: 1140px) {
    .overlay {
        padding: 8rem 4rem;
    }
}

.glassmorphism-button {
    background-color: white;
    color: #4c57f6;
    border: none;
    border-radius: 15px;
    width: 50%;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 23px;
    transition: all 0.5s;
    cursor: pointer;
}

.glassmorphism-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.glassmorphism-button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.glassmorphism-button:hover span {
    padding-right: 25px;
}

.glassmorphism-button:hover span:after {
    opacity: 1;
    right: 0;
}

.globe-header-button {
    background-color: white;
    color: #4c57f6;
    border: none;
    border-radius: 15px;
    width: 50%;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 23px;
    transition: all 0.5s;
    cursor: pointer;
}

.globe-header-button-span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.globe-header-button-span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.globe-header-button:hover .globe-header-button-span {
    padding-right: 25px;
}

.globe-header-button:hover .globe-header-button-span:after {
    opacity: 1;
    right: 0;
}

.globe-header-button-text {
    color: inherit;
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
}

.globe-header-button-text:hover {
    color: #4c57f6;
}