.custom-google-button {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    /* Align items to the start */
    transition: background-color .3s, box-shadow .3s;
    width: 100%;
    min-width: 240px;
    text-align: center;

    padding: 12px 16px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    border: 1px solid black;

    /*color: #757575;*/
    color: black;
    font-size: 1.1em;
    font-weight: 500;
    background-color: white;

    &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }

    &:active {
        background-color: #eeeeee;
    }

    &:focus {
        outline: none;
        box-shadow:
            0 -1px 0 rgba(0, 0, 0, .04),
            0 2px 4px rgba(0, 0, 0, .25),
            0 0 0 3px #c8dafc;
    }
}

.custom-google-button:hover {
    background-color: #f1f1f1;
}

.google-icon-wrapper {
    margin-right: 12px;
    /* Space between icon and text */
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-button-text {
    margin: 0;
    /* Removes default margin */
    white-space: nowrap;
    /* Keeps text on one line */
}