/* Countdown Timer Container */
.countdown-timer {
    width: 100%;
    padding: 20px;
    border: 2px solid red;
    border-radius: 20px;
    /* Keeps it consistent with pricing card */
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 20px;
}

/* Countdown Header */
.countdown-timer-text {
    font-size: 1.5em;
    /* Keep this size unchanged */
    font-weight: 600;
    margin-bottom: 10px;
    /*color: #ff3e3e;*/
    color: red;
    opacity: 0.85;
}

/* Countdown Timer Inline Layout */
.timer-inline {
    display: flex;
    justify-content: center;
    gap: 10px;
    /* Slightly smaller gap */
    margin: 10px 0;
}

/* Timer Box Styling */
.timer-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    /* Slightly smaller box width */
    height: 80px;
    /* Slightly smaller box height */
    /*background: #ff3e3e;*/
    background: red;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    /* Reduced padding */
}

/* Timer Number Styling */
.timer-value {
    font-size: 1.6em;
    /* Slightly smaller numbers */
    font-weight: bold;
    color: white;
    margin-bottom: 5px;
}

/* Timer Label Styling */
.smalltext-countdown {
    font-size: 0.75em;
    /* Keep labels the same size */
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .countdown-timer {
        padding: 15px;
    }

    .timer-box {
        width: 70px;
        /* Smaller box size for mobile */
        height: 70px;
        padding: 10px;
    }

    .timer-value {
        font-size: 1.4em;
        /* Slightly smaller numbers for mobile */
    }

    .smalltext-countdown {
        font-size: 0.7em;
        /* Keep labels the same size */
    }

    .timer-inline {
        gap: 8px;
        /* Reduce gap for mobile */
    }

    .countdown-timer h2 {
        font-size: 1.5em;
        /* Keep heading size unchanged */
    }
}