body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.navbar-brand {
    font-weight: 400;
    transform: translateX(30px) !important;
}

.navbar {
    transition: all 0.5s;
}

.navbar-nav {
    margin: 0 auto;
}

.navbar .navbar-collapse {
    text-align: center;
}

.navbar-nav a {
    margin-left: 30px;
    margin-right: 30px;
}

.welcomemessage {
    display: inline-block;
}

.logoutbutton {
    display: inline-block;
    cursor: pointer;
}

.loginbutton {
    cursor: pointer;
}

@media only screen and (min-width: 992px) {
    .nav-link {
        display: block;
        position: relative;
        padding: 0.2em 0;
    }

    .nav-link::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: white;
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
    }

    .nav-link:hover::after,
    .nav-link:focus::after {
        opacity: 1;
        transform: translate3d(0, 0.2em, 0);
    }

    .welcomemessage,
    .logoutbutton {
        display: inline-block;
    }
}